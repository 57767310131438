

















import { RouterNames } from "@/router/routernames";
import { rol } from "@/shared/dtos/rol";
import { MessageService } from "@/shared/services/message-service";
import rolModule from "@/store/modules/rol-module";
import { UtilsString } from "@/utils/utils-string";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    DynamicTable: () => import("@/views/dynamic_tables/dynamic_table.vue"),
  },
})
export default class RolesLista extends Vue {
  nuevo() {
    this.abrir_formulario("-1");
  }

  editar(obj: rol) {
    this.abrir_formulario(UtilsString.ValueOf(obj.id));
  }

  eliminar(obj: rol) {
    rolModule
      .eliminarrol_permiso(obj.id)
      .then(() => {
        //@ts-ignore
        this.$refs["data_table"].refresh();
      })
      .catch(() => {
        MessageService.toast(
          "No se ha podido eliminar, hay datos asociados a este rol de usuarios",
          MessageService.TypeWarning
        );
      });
  }

  abrir_formulario(id: string) {
    this.$router.push({
      name: RouterNames.rolformulario,
      params: { id: id },
    });
  }
}
